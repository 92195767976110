
import Vue from "vue";

export default Vue.extend({
  name: "PrivacyPolicy",
  data() {
    return {
      green: "#066f6a",
      dialog: true,
    };
  },
  methods: {
    acceptPolicy(): void {
      this.$cookies.set("user-lgpd-accept", true);
      this.dialog = false;
    },
  },
});
